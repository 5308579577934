import { gql } from "@apollo/client";

export const CREATE_AREA_OF_EXPERIENCE_USER_ONE = gql`
  mutation createAreaOfExperienceUserOne(
    $areaOfExperienceId: uuid!,
    $userId: uuid!
  ) {
    insert_area_of_experience_user_one(
      object: { area_of_experience_id: $areaOfExperienceId, user_id: $userId }
    ) {
      area_of_experience_id
      id
      user_id
    }
  }
`;
