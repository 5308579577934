import { gql } from "@apollo/client";

export const CREATE_AREA_OF_EXPERIENCE = gql`
  mutation createAreaOfExperience($description: String!) {
    insert_areas_of_experience_one(object: { description: $description }) {
      description
      id
    }
  }
`;
