import { useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";

import { BtnNoIcon } from "../components/BtnNoIcon";
import { BtnWithIcon } from "../components/BtnWithIcon";
import { CREATE_AREA_OF_EXPERIENCE } from "../graphql/mutations/createAreaOfExperience";
import { CREATE_AREA_OF_EXPERIENCE_USER_ONE } from "../graphql/mutations/createAreaOfExperienceUserOne";
import { GET_USER } from "../graphql/queries/getUser";
import { GET_ALL_AREAS_OF_EXPERIENCE } from "../graphql/queries/getAllAreasOfExperience";
import { UpdateExperiencesModal } from "../components/profile/UpdateExperiencesModal";
import { UpdateProfileModal } from "../components/profile/UpdateProfileModal";
import { PlusBtn } from "../components/profile/PlusBtn";
import { SkeletonBar } from "../components/skeletons/SkeletonBar";
import { ProfileInfo } from "../components/profile/ProfileInfo";

const initialFormState = { description: "" };

export function ProfilePage() {
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormState);
  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);
  const [showUpdateExperiencesModal, setShowUpdateExperiencesModal] =
    useState(false);

  const [getExperiences, getExperiencesResp] = useLazyQuery(
    GET_ALL_AREAS_OF_EXPERIENCE
  );

  const { data: userData } = useQuery(GET_USER, {
    variables: { id: user.attributes.sub },
  });

  const [createAreaOfExperience, { error }] = useMutation(
    CREATE_AREA_OF_EXPERIENCE,
    {
      onCompleted(data) {
        createAreaOfExperienceUser({
          variables: {
            areaOfExperienceId: data.insert_areas_of_experience_one.id,
            userId: user.attributes.sub,
          },
        });
      },
      update(cache, result) {
        // read from cache
        const cachedData = cache.readQuery({
          query: GET_USER,
          variables: { id: user.attributes.sub },
        });
        // update data
        if (
          cachedData?.users_by_pk &&
          result?.data?.insert_areas_of_experience_one
        ) {
          const updatedData = {
            ...cachedData,
            users_by_pk: {
              ...cachedData.users_by_pk,
              user_areas_of_experience: [
                ...cachedData.users_by_pk.user_areas_of_experience,
                {
                  area_of_experience:
                    result.data.insert_areas_of_experience_one,
                },
              ],
            },
          };

          // update cache with modified data
          cache.writeQuery({
            query: GET_USER,
            variables: { id: user.attributes.sub },
            data: updatedData,
          });
        }
      },
    }
  );

  const [createAreaOfExperienceUser] = useMutation(
    CREATE_AREA_OF_EXPERIENCE_USER_ONE
  );

  function handleSubmit() {
    if (formData.description.length > 0) {
      createAreaOfExperience({
        variables: { description: formData.description },
      })
        .then(() => {
          setFormData({ ...formData, description: "" });
        })
        .catch(console.error);
    }
  }

  function handleSetProfileMode() {
    setShowUpdateProfileModal(true);
  }

  function handleEditAreasOfExperience() {
    setShowUpdateExperiencesModal(true);
    getExperiences();
  }

  return (
    <>
      {showUpdateExperiencesModal ? (
        <UpdateExperiencesModal
          setShowModal={setShowUpdateExperiencesModal}
          allExperiences={getExperiencesResp}
          userData={userData}
          useMutation={useMutation}
        />
      ) : null}
      {showUpdateProfileModal ? (
        <UpdateProfileModal
          setShowModal={setShowUpdateProfileModal}
          userData={userData}
          useMutation={useMutation}
        />
      ) : null}
      <div className="flex flex-col items-baseline space-y-3">
        {/* User Profile Information Section */}
        <ProfileInfo
          userData={userData}
          handleSetProfileMode={handleSetProfileMode}
        />
        {/* Areas of Experience Section */}
        <div className="flex space-x-6">
          <h1 className="m-auto text-xl text-left font-bold text-gray-900">
            Areas of Experience List
          </h1>
          <button
            className="inline-block px-5 py-2 text-sm font-medium text-indigo-600 border border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500 focus:outline-none focus:ring transition duration-200"
            onClick={() => handleEditAreasOfExperience()}
            type="button"
          >
            Edit Areas of Experience
          </button>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 w-full">
          {userData ? (
            userData.users_by_pk.user_areas_of_experience.map((experience) => {
              return (
                <BtnNoIcon
                  key={experience.area_of_experience.id}
                  navigate={navigate}
                  navigateTo={`/areasofexperience/${experience.area_of_experience.description}`}
                  description={experience.area_of_experience.description}
                />
              );
            })
          ) : (
            <div className="flex flex-col space-y-3 col-span-6">
              <SkeletonBar num={2} />
            </div>
          )}
        </div>
        {/* Add New Area of Experience Section */}
        <div className="relative">
          <label className="sr-only" htmlFor="experience">
            Add Area of Experience
          </label>

          <input
            className="w-full py-4 pl-3 pr-16 text-sm border-2 border-gray-200"
            id="experience"
            type="text"
            placeholder="Add Area of Experience"
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            value={formData.description}
          />
          <PlusBtn handleSubmit={handleSubmit} />
        </div>
        {error?.graphQLErrors[0]?.extensions?.code ===
        "constraint-violation" ? (
          <span className="text-sm text-red-700 -translate-y-3">
            Experience already exists in index
          </span>
        ) : (
          <span className="text-sm text-gray-600 -translate-y-3">
            Experiences are case-sensitive
          </span>
        )}
        {/* Navigate to Messages Page */}
        <BtnWithIcon
          description="Messages"
          navigate={navigate}
          navigateTo={`/messages/${user.attributes.sub}`}
        />
      </div>
    </>
  );
}
