import { Outlet } from "react-router-dom";

import { NavBar } from "./NavBar";

export function Layout() {
  return (
    <>
      <NavBar />
      <div className="container mx-auto px-6 py-6">
        <Outlet />
      </div>
    </>
  );
}
