import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation updateUser($selfId: uuid!, $changes: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $selfId }, _set: $changes) {
      birth_date
      gender
      id
      first_name
      last_name
      about_me
    }
  }
`;
