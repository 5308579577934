import { BrowserRouter, Routes, Route } from "react-router-dom";

import { RequireAuth } from "./RequireAuth";
import { Layout } from "./components/Layout";
import { LoginPage } from "./pages/LoginPage";
import { HomePage } from "./pages/HomePage";
import { AboutPage } from "./pages/AboutPage";
import { ProfilePage } from "./pages/ProfilePage";
import { ExperiencesIndexPage } from "./pages/ExperiencesIndexPage";
import { ExperiencedUsersPage } from "./pages/ExperiencedUsersPage";
import { UserMessagesPage } from "./pages/UserMessagesPage";
import { NoPageMatch } from "./pages/NoPageMatch";
import { OtherUserProfilePage } from "./pages/OtherUserProfilePage";
import { SingleMessagePage } from "./pages/SingleMessagePage";

export function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="/areasofexperience" element={<ExperiencesIndexPage />} />
          <Route
            path="/areasofexperience/:experienceDescription"
            element={<ExperiencedUsersPage />}
          />
          <Route path="/about" element={<AboutPage />} />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <ProfilePage />
              </RequireAuth>
            }
          />
          <Route
            path="/user/:userId"
            element={
              <RequireAuth>
                <OtherUserProfilePage />
              </RequireAuth>
            }
          />
          <Route
            path="/messages/:userId"
            element={
              <RequireAuth>
                <UserMessagesPage />
              </RequireAuth>
            }
          />
          <Route
            path="/messages/:userId/:chatId"
            element={
              <RequireAuth>
                <SingleMessagePage />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<NoPageMatch />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
