import { gql } from '@apollo/client';

export const CREATE_CHAT = gql`
  mutation createChat(
    $receiverId: uuid!
    $senderId: uuid!
  ) {
    insert_chats_one(
      object: {
        receiver_id: $receiverId,
        sender_id: $senderId
      }
    ) {
      id
      receiver_id
      sender_id
    }
  }
`