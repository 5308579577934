import { SkeletonBar } from "../skeletons/SkeletonBar";
import returnTitleCase from "../../utils/returnTitleCase";
import calculateAge from "../../utils/calculateAge";

export function ProfileInfo({ userData, handleSetProfileMode }) {
  return (
    <>
      <div className="flex space-x-6">
        <h1 className="m-auto text-xl text-left font-bold text-gray-900">
          {userData ? (
            `${returnTitleCase(
              userData.users_by_pk.first_name
            )} ${returnTitleCase(userData.users_by_pk.last_name)}`
          ) : (
            <SkeletonBar num={1} length={24} />
          )}
        </h1>
        <button
          className="inline-block px-5 py-2 text-sm font-medium text-indigo-600 border border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500 focus:outline-none focus:ring transition duration-200"
          onClick={() => handleSetProfileMode()}
          type="button"
        >
          Edit Profile
        </button>
      </div>
      <div className="flex">
        Age:{" "}
        {userData ? (
          calculateAge(userData.users_by_pk.birth_date)
        ) : (
          <SkeletonBar num={1} length={12} />
        )}
      </div>
      <div className="flex">
        Identify as:{" "}
        {userData ? (
          userData.users_by_pk.gender
        ) : (
          <SkeletonBar num={1} length={36} />
        )}{" "}
      </div>
      <div className="flex">
        About me:{" "}
        {userData ? (
          userData.users_by_pk.about_me
        ) : (
          <SkeletonBar num={1} length={36} />
        )}
      </div>
    </>
  );
}
