export function UserResultSkeleton({ num }) {
  function getSkeletons(num) {
    let skeletonArr = [];
    for (let i = 0; i < num; i++) {
      skeletonArr.push(
        <li key={i} className="border-gray-300 flex flex-row mb-2 shadow border bg-white rounded-md p-2">
          <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
            <div className="w-12 bg-gray-300 h-12 rounded-full "></div>
            <div className="flex flex-col space-y-3">
              <div className="w-36 bg-gray-300 h-6 rounded-md "></div>
              <div className="w-24 bg-gray-300 h-6 rounded-md "></div>
            </div>
          </div>
        </li>
      );
    }
    return skeletonArr;
  }

  return <>{getSkeletons(num)}</>;
}
