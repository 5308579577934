import { gql } from "@apollo/client";

export const GET_CHAT_ITEMS_SUB = gql`
  subscription getChatItemsSub($chatId: uuid!) {
    chat_items(where: { chat_id: { _eq: $chatId } }) {
      message
      item_type
      image_url
      id
      chat_id
      receiver {
        first_name
        id
      }
      sender {
        first_name
        id
      }
    }
  }
`;
