import { gql } from "@apollo/client";

export const GET_USER = gql`
  query getUser($id: uuid!) {
    users_by_pk(id: $id) {
      user_areas_of_experience {
        area_of_experience {
          description
          id
        }
      }
      birth_date
      email
      first_name
      gender
      id
      last_name
      about_me
    }
  }
`;
