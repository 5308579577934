import { gql } from "@apollo/client";

export const GET_ALL_AREAS_OF_EXPERIENCE = gql`
  query getAllAreasOfExperience {
    areas_of_experience {
      id
      description
    }
  }
`;
