import { gql } from "@apollo/client";

export const GET_MY_CHATS_WITH_SPECIFIC_USER = gql`
  query getMyChatsWithSpecificUser($userId: uuid!, $myId: uuid!) {
    chats(
      where: {
        _or: [
          {
            _and: [
              { receiver_id: { _eq: $myId } },
              { sender_id: { _eq: $userId } }
            ]
          }
          {
            _and: [
              { receiver_id: { _eq: $userId } },
              { sender_id: { _eq: $myId } }
            ]
          }
        ]
      }
    ) {
      receiver_id
      sender_id
      id
    }
  }
`;
