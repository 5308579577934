import { useState } from "react";

import { UpdateExperiencesCheckboxes } from "./UpdateExperiencesCheckboxes";
import { CREATE_AREA_OF_EXPERIENCE_USERS } from "../../graphql/mutations/createAreaOfExperienceUsers";
import { DELETE_AREA_OF_EXPERIENCE_USERS } from "../../graphql/mutations/deleteAreaOfExperienceUsers";
import { GET_USER } from "../../graphql/queries/getUser";

export function UpdateExperiencesModal({
  setShowModal,
  allExperiences,
  userData,
  useMutation,
}) {
  const [updatedExps, setUpdatedExps] = useState({});
  const [createAreaOfExperienceUsers] = useMutation(
    CREATE_AREA_OF_EXPERIENCE_USERS,
    {
      update(cache, result) {
        const cachedData = cache.readQuery({
          query: GET_USER,
          variables: { id: userData.users_by_pk.id },
        });

        if (
          cachedData.users_by_pk &&
          result?.data?.insert_area_of_experience_user
        ) {
          const updatedData = {
            ...cachedData,
            users_by_pk: {
              ...cachedData.users_by_pk,
              user_areas_of_experience: [
                ...cachedData.users_by_pk.user_areas_of_experience,
                ...result.data.insert_area_of_experience_user.returning,
              ],
            },
          };
          cache.writeQuery({
            query: GET_USER,
            variables: { id: userData.users_by_pk.id },
            data: updatedData,
          });
        }
      },
    }
  );
  const [deleteAreaOfExperienceUsers] = useMutation(
    DELETE_AREA_OF_EXPERIENCE_USERS,
    {
      update(cache, result) {
        const cachedData = cache.readQuery({
          query: GET_USER,
          variables: { id: userData.users_by_pk.id },
        });

        if (
          cachedData.users_by_pk &&
          result?.data?.delete_area_of_experience_user
        ) {
          const removedDescriptionIds =
            result.data.delete_area_of_experience_user.returning.map(
              (exp) => exp.area_of_experience.id
            );

          const filteredExps =
            cachedData.users_by_pk.user_areas_of_experience.filter(
              (exp) => !removedDescriptionIds.includes(exp.area_of_experience.id)
            );

          const updatedData = {
            ...cachedData,
            users_by_pk: {
              ...cachedData.users_by_pk,
              user_areas_of_experience: [...filteredExps],
            },
          };

          cache.writeQuery({
            query: GET_USER,
            variables: { id: userData.users_by_pk.id },
            data: updatedData,
          });
        }
      },
    }
  );

  function experiencesCheckList() {
    let userExperiences = {};

    if (userData?.users_by_pk?.user_areas_of_experience.length > 0) {
      userData.users_by_pk.user_areas_of_experience.forEach((exp) => {
        userExperiences[exp.area_of_experience?.description] = {
          id: exp.area_of_experience?.id,
          description: exp.area_of_experience?.description,
          checked: true,
        };
      });
    }

    if (allExperiences.data) {
      allExperiences.data.areas_of_experience.forEach((exp) => {
        if (!userExperiences.hasOwnProperty(exp.description)) {
          userExperiences[exp.description] = {
            id: exp.id,
            description: exp.description,
            checked: false,
          };
        }
      });
    }

    return userExperiences;
  }

  function handleSaveChanges() {
    let removeExpList = [];
    let addExpList = [];
    for (const key in updatedExps) {
      if (updatedExps[key].update) {
        if (updatedExps[key].checked) {
          addExpList.push({
            area_of_experience_id: updatedExps[key].id,
            user_id: userData.users_by_pk.id,
          });
        } else {
          removeExpList.push(updatedExps[key].id);
        }
      }
    }
    if (addExpList.length > 0) {
      createAreaOfExperienceUsers({
        variables: { objects: addExpList },
      });
    }

    if (removeExpList.length > 0) {
      deleteAreaOfExperienceUsers({
        variables: { exp: removeExpList, userId: userData.users_by_pk.id },
      });
    }

    setShowModal(false);
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Edit Areas of Experience
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              {allExperiences.data && userData.users_by_pk ? (
                <UpdateExperiencesCheckboxes
                  experiencesObj={experiencesCheckList()}
                  updatedExps={updatedExps}
                  setUpdatedExps={setUpdatedExps}
                />
              ) : null}
              <p></p>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => handleSaveChanges()}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
