export function GenderOptions() {
  return (
    <>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
      <option value="Non-Binary">Non-Binary</option>
      <option value="Gender Fluid">Gender Fluid</option>
      <option value="Agender">Agender</option>
      <option value="Transgender Man">Transgender Man</option>
      <option value="Transgender Woman">Transgender Woman</option>
    </>
  );
}
