import { useLazyQuery, useMutation } from "@apollo/client";

import { GET_MY_CHATS_WITH_SPECIFIC_USER } from "../../graphql/queries/getMyChatsWithSpecificUser";
import { CREATE_CHAT } from "../../graphql/mutations/createChat";
import returnTitleCase from "../../utils/returnTitleCase";
import calculateAge from "../../utils/calculateAge";

export function UserSearchResultCard({ user, navigate, currentUser }) {
  const [getChat] = useLazyQuery(GET_MY_CHATS_WITH_SPECIFIC_USER, {
    onCompleted(data) {
      if (data.chats.length > 0) {
        console.log("getMyChatsWithSpecificUser query has chat", data.chats);
        navigate(
          `/messages/${currentUser?.attributes?.sub}/${data.chats[0].id}`,
          {
            state: {
              chatId: data.chats[0].id,
              otherUserId: user.user.id,
              otherUserName: `${user.user.first_name} ${user.user.last_name}`,
            },
          }
        );
      } else {
        console.log("getMyChatsWithSpecificUser query no chat", data.chats);
        createChat({
          variables: {
            receiverId: user.user.id,
            senderId: currentUser?.attributes?.sub,
          },
        });
      }
    },
  });

  const [createChat] = useMutation(CREATE_CHAT, {
    update(cache, result) {
      const cachedData = cache.readQuery({
        query: GET_MY_CHATS_WITH_SPECIFIC_USER,
        variables: {
          userId: user.user.id,
          myId: currentUser?.attributes?.sub,
        },
      });

      console.log("cached data", cachedData);
      console.log("mutation result", result?.data?.insert_chats_one);

      const updatedData = {
        chats: [result?.data?.insert_chats_one],
      };

      if (result?.data?.insert_chats_one) {
        cache.writeQuery({
          query: GET_MY_CHATS_WITH_SPECIFIC_USER,
          variables: {
            userId: user.user.id,
            myId: currentUser?.attributes?.sub,
          },
          data: updatedData,
        });
      }
    },
  });

  function handleProfileNavigate() {
    const url =
      currentUser?.attributes?.sub === user.user.id
        ? `/profile`
        : `/user/${user.user.id}`;
    navigate(url);
  }

  function handleMessageNavigate() {
    getChat({
      variables: {
        userId: user.user.id,
        myId: currentUser?.attributes?.sub,
      },
    });
  }

  return (
    <li className="border-gray-400 flex flex-row mb-2">
      <div className="shadow border bg-white rounded-md flex flex-1 items-center p-4">
        {/* profile pic */}
        <div className="flex flex-col w-10 h-10 justify-center items-center mr-4">
          {/* <img
            alt="profile pic"
            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
            className="mx-auto object-cover rounded-full h-10 w-10"
          /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-10 h-10"
          >
            <path
              fillRule="evenodd"
              d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        {/* name and age */}
        <div className="flex-1 pl-1 mr-4 basis-1/6 grow-0 text-left">
          <div
            className="font-medium hover:underline cursor-pointer"
            onClick={() => handleProfileNavigate()}
          >{`${returnTitleCase(user.user.first_name)} ${returnTitleCase(
            user.user.last_name
          )}`}</div>
          <div className="text-gray-600 text-sm">
            {`Age: ${calculateAge(user.user.birth_date)}`}
          </div>
        </div>
        {/* list of experiences */}
        <ul className="flex flex-wrap flex-1 text-gray-600 text-xs">
          {user.user.user_areas_of_experience.map((experience) => {
            return (
              <li
                key={experience.area_of_experience.id}
                className="p-1 leading-none text-xs text-gray-900 font-medium"
              >
                {experience.area_of_experience.description}
              </li>
            );
          })}
        </ul>
        {/* message button */}
        {currentUser?.attributes?.sub !== user.user.id ? (
          <button
            className="w-24 flex justify-center border hover:z-10 hover:bg-gray-50 focus:outline-none focus:border-indigo-600 active:opacity-75 transition duration-200"
            type="button"
            onClick={handleMessageNavigate}
          >
            Message
          </button>
        ) : null}
      </div>
    </li>
  );
}
