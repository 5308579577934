import { gql } from "@apollo/client";

export const CREATE_CHAT_ITEM = gql`
  mutation createChatItem(
    $receiverId: uuid!
    $senderId: uuid!
    $chatId: uuid!
    $message: String!
    $itemType: String!
  ) {
    insert_chat_items_one(
      object: {
        receiver_id: $receiverId,
        sender_id: $senderId,
        chat_id: $chatId,
        message: $message,
        item_type: $itemType
      }
    ) {
      message
      item_type
      image_url
      id
      chat_id
      receiver {
        first_name
        id
      }
      sender {
        first_name
        id
      }
    }
  }
`;
