import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { UserSearchResultCard } from "../components/searchResults/UserSearchResultCard";
import { GET_AREAS_OF_EXPERIENCE } from "../graphql/queries/getAreasOfExperience";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { BtnWithIcon } from "../components/BtnWithIcon";
import { BtnNoIcon } from "../components/BtnNoIcon";
import { UserResultSkeleton } from "../components/skeletons/UserResultSkeleton";

const initialFormState = { description: "" };

export function ExperiencedUsersPage() {
  const [formData, setFormData] = useState(initialFormState);
  const params = useParams();
  const { user: currentUser } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_AREAS_OF_EXPERIENCE, {
    variables: { ilike: `%${params.experienceDescription}%` },
  });

  function handleSubmit(e) {
    e.preventDefault();
    setFormData({ ...formData, description: "" });
  }

  return (
    <div className="flex flex-col">
      <div className="relative">
        <form onSubmit={(e) => handleSubmit(e)}>
          <label className="sr-only" htmlFor="experience">
            Search Areas of Experience
          </label>
          <input
            className="w-full p-3 mt-1 text-sm border-2 mb-3 border-gray-200"
            id="experience"
            type="text"
            placeholder="Experience"
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            value={formData.description}
          />
          <div className="flex items-center justify-between">
            <BtnWithIcon
              description="Experiences Index"
              navigate={navigate}
              navigateTo="/areasofexperience"
            />
            <BtnNoIcon
              description="Search"
              navigate={navigate}
              navigateTo={`/areasofexperience/${formData.description}`}
              type="submit"
            />
          </div>
        </form>
      </div>
      <h1 className="mt-4 text-3xl font-bold text-gray-900">
        Users that have experience in {params.experienceDescription}
      </h1>
      <div className="container flex flex-col mx-auto w-full items-center justify-center">
        <ul className="flex flex-col w-full">
          {loading ? (
            <UserResultSkeleton num={3} />
          ) : data &&
            data.areas_of_experience[0].area_of_experience_users.length > 0 ? (
            data.areas_of_experience[0].area_of_experience_users.map((user) => {
              return (
                <UserSearchResultCard
                  key={user.user.id}
                  user={user}
                  navigate={navigate}
                  currentUser={currentUser}
                />
              );
            })
          ) : (
            <div>No results</div>
          )}
        </ul>
      </div>
    </div>
  );
}
