import { gql } from "@apollo/client";

export const GET_MY_CHATS = gql`
  query getMyChats($userId: uuid!) {
    chats(
      where: {
        # fetch all chats that have existing chat items
        _and: { chat_items: {} },
        _or: [
          { receiver_id: { _eq: $userId } }
          { sender_id: { _eq: $userId } }
        ]
      }
    ) {
      receiver {
        id
        first_name
        last_name
      }
      sender {
        id
        first_name
        last_name
      }
      id
      chat_items(limit: 1, order_by: { created_at: desc }) {
        id
        message
        created_at
      }
    }
  }
`;
