import { gql } from "@apollo/client";

export const GET_AREAS_OF_EXPERIENCE = gql`
  query getAreasOfExperience($ilike: String!) {
    areas_of_experience(where: { description: { _ilike: $ilike } }) {
      area_of_experience_users {
        user {
          id
          first_name
          last_name
          gender
          birth_date
          last_seen
          user_areas_of_experience {
            id
            area_of_experience {
              description
              id
            }
          }
        }
      }
      id
      description
    }
  }
`;
