export function UpdateExperiencesCheckboxes({
  experiencesObj,
  updatedExps,
  setUpdatedExps,
}) {
  function returnCheckboxes() {
    if (experiencesObj) {
      let experiencesList = [];
      for (const key in experiencesObj) {
        experiencesList.push(experiencesObj[key]);
      }

      return experiencesList.map((experience) => {
        return (
          <li key={experience.id} className="flex-initial">
            <div className="flex items-center px-3">
              <input
                defaultChecked={experience.checked}
                id={experience.id}
                type="checkbox"
                value={experience.description}
                onChange={(e) => handleCheckboxUpdate(e)}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2 "
              />
              <label
                htmlFor="vue-checkbox-list"
                className="py-3 ml-2 w-full text-sm font-medium text-gray-900"
              >
                {experience.description}
              </label>
            </div>
          </li>
        );
      });
    }
  }

  function handleCheckboxUpdate(e) {
    const description = e.target.value;

    if (!updatedExps.hasOwnProperty(description)) {
      let updatedValue = {};
      updatedValue[description] = {
        id: e.target.id,
        checked: e.target.checked,
        update: true,
      };
      setUpdatedExps({ ...updatedExps, ...updatedValue });
    } else {
      let updatedValue = {};
      updatedValue[description] = {
        id: e.target.id,
        checked: e.target.checked,
        update: !updatedExps[description].update,
      };
      setUpdatedExps({ ...updatedExps, ...updatedValue });
    }
  }

  return (
    <ul className="flex flex-wrap items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex">
      {returnCheckboxes()}
    </ul>
  );
}
