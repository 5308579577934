import { useState } from "react";

import { UPDATE_USER } from "../../graphql/mutations/updateUser";
import { GenderOptions } from "../GenderOptions";

export function UpdateProfileModal({ setShowModal, userData, useMutation }) {
  const [updatedUserData, setUpdatedUserData] = useState({});
  const [updateUser] = useMutation(UPDATE_USER);
  function handleOnChange(e) {
    setUpdatedUserData({ ...updatedUserData, [e.target.name]: e.target.value });
  }
  function handleSaveChanges() {
    if (Object.keys(updatedUserData).length > 0) {
      updateUser({
        variables: {
          selfId: userData.users_by_pk.id,
          changes: {
            ...updatedUserData,
          },
        },
      });
    }
    setShowModal(false);
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Edit Profile</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <form action="#">
                <div className="flex gap-4 mb-2">
                  <div className=" relative ">
                    <input
                      type="text"
                      id="edit-first-name"
                      className=" rounded-md flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      name="first_name"
                      placeholder="First Name"
                      defaultValue={
                        userData ? userData.users_by_pk.first_name : null
                      }
                      onChange={handleOnChange}
                    />
                  </div>
                  <div className=" relative ">
                    <input
                      type="text"
                      id="edit-last-name"
                      className=" rounded-md flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      name="last_name"
                      placeholder="Last Name"
                      defaultValue={
                        userData ? userData.users_by_pk.last_name : null
                      }
                      onChange={handleOnChange}
                    />
                  </div>
                </div>
                <div className="flex flex-col mb-2">
                  <div className=" relative ">
                    <select
                      className="block w-52 text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      name="gender"
                      defaultValue={
                        userData ? userData.users_by_pk.gender : null
                      }
                      onChange={handleOnChange}
                    >
                      <GenderOptions />
                    </select>
                  </div>
                </div>
                <div className="flex flex-col mb-2">
                  <div className=" relative ">
                    <label className="text-gray-700" htmlFor="name">
                      <textarea
                        className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                        id="about"
                        placeholder="About me"
                        name="about_me"
                        rows="5"
                        cols="40"
                        defaultValue={
                          userData ? userData.users_by_pk.about_me : null
                        }
                        onChange={handleOnChange}
                      ></textarea>
                    </label>
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => handleSaveChanges()}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
