import { gql } from '@apollo/client';

export const CREATE_AREA_OF_EXPERIENCE_USERS = gql`
  mutation createAreaOfExperienceUsers($objects:[area_of_experience_user_insert_input!]!) {
  insert_area_of_experience_user(objects: $objects) {
    returning {
      area_of_experience {
        id
        description
      }
    }
  }
}
`