export function BtnWithIcon({ children, description, navigate, navigateTo, type="button" }) {
  return (
    <button
      className="inline-flex items-center px-5 py-2 text-indigo-600 border border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500 focus:outline-none focus:ring transition duration-200"
      onClick={() => navigate(navigateTo)}
      type={type}
    >
      <span className="text-sm font-medium">{description}</span>
      {children ? (
        children
      ) : (
        <svg
          className="w-5 h-5 ml-3"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17 8l4 4m0 0l-4 4m4-4H3"
          />
        </svg>
      )}
    </button>
  );
}
