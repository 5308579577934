export function BtnNoIcon({ description, navigate, navigateTo, type="button" }) {
  return (
    <button
      className="inline-block px-5 py-2 text-sm font-medium text-white bg-indigo-600 border border-indigo-600 active:text-indigo-500 hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring transition duration-200"
      onClick={() => navigate(navigateTo)}
      type={type}
    >
      {description}
    </button>
  );
}
