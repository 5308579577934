import calculateTimeElapsed from "../utils/calculateTimeElapsed";

export function SingleChat({ chat, userId, navigate }) {
  const otherUser =
    chat.receiver.id === userId
      ? // ? `${chat.sender.first_name} ${chat.sender.last_name}`
        chat.sender
      : // : `${chat.receiver.first_name} ${chat.receiver.last_name}`;
        chat.receiver;

  function handleNavigate() {
    navigate(`/messages/${userId}/${chat.id}`, {
      state: {
        chatId: chat.id,
        otherUserId: otherUser.id,
        otherUserName: `${otherUser.first_name} ${otherUser.last_name}`,
      },
    });
  }
  return (
    <li className="flex flex-row" key={chat.id}>
      <div className="flex flex-1 items-center p-4">
        {/* profile pic */}
        <div className="flex flex-col w-10 h-10 justify-center items-center mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-10 h-10"
          >
            <path
              fillRule="evenodd"
              d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        {/* name and message */}
        <div className=" flex-1 pl-1 mr-16">
          <div className="font-medium text-left">{`${otherUser.first_name} ${otherUser.last_name}`}</div>
          <div className="text-gray-600 text-sm text-left">
            {chat.chat_items[0].message}
          </div>
        </div>
        {/* time elapsed since last message */}
        <div className="text-gray-600 text-xs">
          {calculateTimeElapsed(chat.chat_items[0].created_at)}
        </div>
        {/* navigate to chat */}
        <button
          className="w-24 text-right flex justify-end"
          onClick={handleNavigate}
        >
          <svg
            width="20"
            fill="currentColor"
            height="20"
            className="hover:text-gray-800 dark:hover:text-white dark:text-gray-200 text-gray-500"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
          </svg>
        </button>
      </div>
    </li>
  );
}
