import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { BtnWithIcon } from "../components/BtnWithIcon";
import { BtnNoIcon } from "../components/BtnNoIcon";

const initialFormState = { description: "" };

export function HomePage() {
  const [formData, setFormData] = useState(initialFormState);
  const navigate = useNavigate();

  return (
    <div className="flex-col">
      <h1 className="mt-4 text-3xl font-bold text-gray-900">Search Areas of Experience</h1>
      <div className="relative">
        <form onSubmit={(e) => e.preventDefault()}>
          <label className="sr-only" htmlFor="experience">
            Search Areas of Experience
          </label>
          <input
            className="w-full p-3 mt-1 text-sm border-2 mb-3 border-gray-200"
            id="experience"
            type="text"
            placeholder="Experience"
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            value={formData.description}
          />
          <div className="flex items-center justify-between">
            <BtnWithIcon
              description="Experiences Index"
              navigate={navigate}
              navigateTo="/areasofexperience"
            />
            <BtnNoIcon
              description="Search"
              navigate={navigate}
              navigateTo={`/areasofexperience/${formData.description}`}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
