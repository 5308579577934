import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_ALL_AREAS_OF_EXPERIENCE } from "../graphql/queries/getAllAreasOfExperience";
import { BtnNoIcon } from "../components/BtnNoIcon";
import { SkeletonBar } from "../components/skeletons/SkeletonBar";

export function ExperiencesIndexPage() {
  const navigate = useNavigate();
  const { data } = useQuery(GET_ALL_AREAS_OF_EXPERIENCE);

  return (
    <div className="flex flex-col space-y-3">
      <h1 className="mt-4 text-3xl font-bold text-gray-900">
        Experiences Index
      </h1>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
        {data ? (
          data.areas_of_experience.map((experience) => {
            return (
              <BtnNoIcon
                key={experience.id}
                navigate={navigate}
                navigateTo={`/areasofexperience/${experience.description}`}
                description={experience.description}
              />
            );
          })
        ) : (
          <div className="flex flex-col space-y-3 col-span-6">
            <SkeletonBar num={3}/>
          </div>
        )}
      </div>
    </div>
  );
}
