import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Authenticator,
  SelectField,
  useAuthenticator,
  View,
} from "@aws-amplify/ui-react";
import { GenderOptions } from "../components/GenderOptions";

const formFields = {
  signUp: {
    given_name: {
      labelHidden: true,
      placeholder: "First Name",
      isRequired: true,
    },
    family_name: {
      labelHidden: true,
      placeholder: "Last Name",
      isRequired: true,
    },
  },
};

export function LoginPage() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

  let from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <View className="auth-wrapper">
      <Authenticator
        formFields={formFields}
        components={{
          SignUp: {
            FormFields() {
              const { validationErrors } = useAuthenticator();

              return (
                <>
                  <Authenticator.SignUp.FormFields />
                  <SelectField
                    label="Gender"
                    name="gender"
                    labelHidden
                    placeholder="Gender"
                  >
                    <GenderOptions />
                  </SelectField>
                </>
              );
            },
          },
        }}
      ></Authenticator>
    </View>
  );
}
