import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import "@aws-amplify/ui-react/styles.css";

import { MyRoutes } from "./MyRoutes";
import awsconfig from "./aws-exports";
import "./App.css";
import { getMainDefinition } from "@apollo/client/utilities";

Amplify.configure(awsconfig);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const currentSession = await Auth.currentSession();
    const jwtToken = currentSession.getIdToken().getJwtToken();
    return {
      headers: {
        ...headers,
        Authorization: jwtToken ? `Bearer ${jwtToken}` : "",
      },
    };
  } catch (e) {
    console.log(e);
    return {
      headers: {
        ...headers,
      },
    };
  }
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WS_LINK,
    connectionParams: async () => {
      try {
        const currentSession = await Auth.currentSession();
        const jwtToken = currentSession.getIdToken().getJwtToken();
        return {
          headers: {
            Authorization: jwtToken ? `Bearer ${jwtToken}` : "",
          },
        };
      } catch (e) {
        console.log(e);
        return {};
      }
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <Authenticator.Provider>
      <ApolloProvider client={client}>
        <MyRoutes />
      </ApolloProvider>
    </Authenticator.Provider>
  );
}

export default App;
