import { gql } from '@apollo/client';

export const DELETE_AREA_OF_EXPERIENCE_USERS = gql`
  mutation deleteAreaOfExperienceUsers($exp: [uuid!]!, $userId: uuid!) {
    delete_area_of_experience_user(where: {_and: {area_of_experience_id: {_in: $exp}, user_id: {_eq: $userId}}}) {
    returning {
      area_of_experience {
        id
        description
      }
    }
  }
  }
`