export function PlusBtn({ handleSubmit }) {
  return (
    <button
      className="absolute p-2 text-white -translate-y-1/2 bg-blue-600 rounded-full top-1/2 right-4 hover:bg-blue-200 transition duration-200"
      type="button"
      onClick={handleSubmit}
    >
      <svg
        className="w-4 h-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </svg>
    </button>
  );
}
