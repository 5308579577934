import { useMutation, useSubscription } from "@apollo/client";
import { useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { CREATE_CHAT_ITEM } from "../graphql/mutations/createChatItem";
import { GET_CHAT_ITEMS_SUB } from "../graphql/subscriptions/getChatItemsSub";
import { EmojiBtn } from "../components/userMessages/EmojiBtn";
import { AttachmentBtn } from "../components/userMessages/AttachmentBtn";

const initialFormState = { message: "" };

export function SingleMessagePage() {
  const location = useLocation();
  const [formData, setFormData] = useState(initialFormState);
  const { user } = useAuthenticator((context) => [context.user]);
  const params = useParams();
  const navigate = useNavigate();

  const [createChatItem] = useMutation(CREATE_CHAT_ITEM);

  const { data } = useSubscription(GET_CHAT_ITEMS_SUB, {
    variables: { chatId: params.chatId },
  });

  function handleSubmit() {
    const { otherUserId } = location.state;
    // if (data.chat_items.length > 0) {
    //   otherUserId =
    //     data.chat_items[0].receiver.id === user.attributes.sub
    //       ? data.chat_items[0].sender.id
    //       : data.chat_items[0].receiver.id;
    // }
    createChatItem({
      variables: {
        receiverId: otherUserId,
        senderId: user.attributes.sub,
        chatId: params.chatId,
        message: formData.message,
        itemType: "Text",
      },
    });
    setFormData({ ...formData, message: "" });
  }

  return (
    <div className="flex-1 p:2 sm:p-6 justify-between flex flex-col h-screen max-h-[600px]">
      {/* Header */}
      <div className="flex sm:items-center justify-between py-3 border-b-2 border-gray-200">
        {/* Header Icon Buttons */}
        <div className="flex items-center space-x-2">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
            onClick={() => navigate(-1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M15 18l-6-6 6-6" />
            </svg>
          </button>
        </div>
        {/* Other user picture and name */}
        <div className="relative flex flex-col items-start basis-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-10 h-10"
          >
            <path
              fillRule="evenodd"
              d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
              clipRule="evenodd"
            />
          </svg>
          <div className="leading-tight font-medium text-gray-700">
            {location.state?.otherUserName
              ? `${location.state.otherUserName}`
              : null}
          </div>
        </div>
      </div>
      {/* Message container */}
      <div className="relative w-full p-6 overflow-y-auto h-[40rem] scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
        <ul className="space-y-2">
          {data &&
            data.chat_items.map((chatItem, index, array) => {
              const senderIsMe = chatItem.sender.id === user.attributes.sub;

              return (
                <li
                  key={chatItem.id}
                  className={`flex ${
                    senderIsMe ? "justify-end" : "justify-start"
                  }`}
                >
                  <div
                    className={`relative max-w-md px-4 py-2 rounded shadow ${
                      senderIsMe
                        ? "bg-blue-600 text-white"
                        : "bg-gray-300 text-gray-600"
                    }`}
                  >
                    <span className="block text-left">{chatItem.message}</span>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>

      {/* Message Input Container */}
      <div className="flex items-center justify-between w-full p-3 border-t border-gray-300">
        <EmojiBtn />
        <AttachmentBtn />
        <input
          type="text"
          placeholder="Message"
          className="block w-full py-2 pl-4 mx-3 bg-gray-100 rounded-md outline-none focus:text-gray-700"
          name="message"
          value={formData.message}
          onChange={(e) =>
            setFormData({ ...formData, message: e.target.value })
          }
          required
        />
        <button type="submit" onClick={handleSubmit}>
          <svg
            className="w-5 h-5 text-gray-500 origin-center transform rotate-90"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
          </svg>
        </button>
      </div>
    </div>
  );
}
