export function AboutPage() {
  return (
    <div className="flex flex-col">
      <h1 className="mt-4 text-3xl font-bold text-gray-900">
        About Experience Knows
      </h1>
      <p className="mt-6 text-gray-500 dark:text-gray-300">
        Experience Knows is on a mission to connect people struggling with
        particular issues, to other, like minded individuals who have
        successfully gone through similar problems. We believe that a big aspect
        of overcoming obstacles rests in feeling and being heard and understood.
        And who understands you more than someone who has been in the same
        situation?
      </p>

      <p className="mt-6 text-gray-500 dark:text-gray-300">
        Looking for help? Ask someone who’s experienced. Because,
        Experience knows.
      </p>
    </div>
  );
}
