import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_MY_CHATS } from "../graphql/queries/getMyChats";
import { SingleChat } from "../components/SingleChat";

export function UserMessagesPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { data } = useQuery(GET_MY_CHATS, {
    variables: { userId: params.userId },
  });

  return (
    <div className="h-full">
      {/* <!-- Card --> */}
      <div className="relative max-w-[700px] mx-auto">
        {/* <!-- Card body --> */}
        <div className="py-3 px-5">
          <h3 className="text-xs font-semibold uppercase text-gray-400 mb-1">
            Chats
          </h3>
          {/* <!-- Chat list --> */}
          <div className="container flex flex-col mx-auto w-full items-center justify-center bg-white rounded-lg shadow">
            <ul className="flex flex-col divide divide-y">
              {data &&
                data.chats.length > 0 &&
                data.chats.map((chat) => {
                  return (
                    <SingleChat
                      key={chat.id}
                      chat={chat}
                      userId={params.userId}
                      navigate={navigate}
                    />
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
