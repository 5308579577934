import { useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { NavLink, useNavigate } from "react-router-dom";

export function NavBar() {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  function handleToggle() {
    setOpen(!open);
  }

  function logOut() {
    signOut();
    navigate("/login");
  }

  return (
    <>
      <nav className="relative p-4 flex justify-between items-center bg-white">
        <NavLink className="text-3xl font-bold leading-none" to="/">
          Experience Knows
        </NavLink>
        <div className="lg:hidden">
          {/* burger toggle button */}
          <button
            className="navbar-burger flex items-center text-blue-600 p-3"
            onClick={() => handleToggle()}
          >
            <svg
              className="block h-4 w-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
        <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-sm text-blue-600 font-bold hover:text-gray-600"
                  : "text-sm text-gray-400 hover:text-blue-500"
              }
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li className="text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              className="w-4 h-4 current-fill"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-sm text-blue-600 font-bold hover:text-gray-600"
                  : "text-sm text-gray-400 hover:text-blue-500"
              }
              to="/about"
            >
              About Us
            </NavLink>
          </li>
          <li className="text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              className="w-4 h-4 current-fill"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-sm text-blue-600 font-bold hover:text-gray-600"
                  : "text-sm text-gray-400 hover:text-blue-500"
              }
              to="/profile"
            >
              Profile
            </NavLink>
          </li>
        </ul>
        {route !== "authenticated" ? (
          <NavLink
            className="hidden lg:inline-block lg:mr-3 py-2 px-6 bg-blue-500 hover:bg-blue-600 text-sm text-white font-bold transition duration-200"
            to="/login"
          >
            Sign In
          </NavLink>
        ) : (
          <button
            className="hidden lg:inline-block lg:mr-3 py-2 px-6 bg-gray-50 hover:bg-gray-100 text-sm text-gray-900 font-bold transition duration-200"
            onClick={() => logOut()}
          >
            Sign Out
          </button>
        )}
      </nav>
      <div className={`navbar-menu relative z-50 ${open ? "" : "hidden"}`}>
        <div
          className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"
          onClick={() => handleToggle()}
        ></div>
        <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <NavLink className="mr-auto text-3xl font-bold leading-none" to="/">
              Experience Knows
            </NavLink>
            <button className="navbar-close" onClick={() => handleToggle()}>
              <svg
                className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div>
            <ul>
              <li className="mb-1">
                <NavLink
                  className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                  to="/"
                  onClick={() => handleToggle()}
                >
                  Home
                </NavLink>
              </li>
              <li className="mb-1">
                <NavLink
                  className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                  to="/about"
                  onClick={() => handleToggle()}
                >
                  About Us
                </NavLink>
              </li>
              <li className="mb-1">
                <NavLink
                  className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                  to="/profile"
                  onClick={() => handleToggle()}
                >
                  Profile
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="pt-6">
              {route !== "authenticated" ? (
                <NavLink
                  className="block px-4 py-3 mb-3 text-xs text-center font-semibold leading-none bg-blue-500 hover:bg-blue-600 text-white"
                  to="/login"
                  onClick={() => handleToggle()}
                >
                  Sign in
                </NavLink>
              ) : (
                <button
                  className="block px-4 py-3 mb-2 text-xs text-center font-semibold leading-none bg-gray-50 hover:bg-gray-100"
                  onClick={() => {
                    logOut();
                    handleToggle();
                  }}
                >
                  Sign Out
                </button>
              )}
            </div>
            <p className="my-4 text-xs text-center text-gray-400">
              <span>Copyright © 2021</span>
            </p>
          </div>
        </nav>
      </div>
    </>
  );
}
