import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { BtnNoIcon } from "../components/BtnNoIcon";
import { GET_USER } from "../graphql/queries/getUser";
import { SkeletonBar } from "../components/skeletons/SkeletonBar";
import returnTitleCase from "../utils/returnTitleCase";
import calculateAge from "../utils/calculateAge";

export function OtherUserProfilePage() {
  const navigate = useNavigate();
  const params = useParams();

  const { data: userData } = useQuery(GET_USER, {
    variables: { id: params.userId },
  });

  return (
    <>
      <div className="flex flex-col items-baseline space-y-3">
        <h1 className="my-auto text-xl text-left font-bold text-gray-900">
          {userData ? (
            `${returnTitleCase(
              userData.users_by_pk.first_name
            )} ${returnTitleCase(userData.users_by_pk.last_name)}`
          ) : (
            <SkeletonBar num={1} length={24} />
          )}
        </h1>
        <div className="flex">
          Age:{" "}
          {userData ? (
            calculateAge(userData.users_by_pk.birth_date)
          ) : (
            <SkeletonBar num={1} length={12} />
          )}
        </div>
        <div className="flex">
          Identify as:{" "}
          {userData ? (
            userData.users_by_pk.gender
          ) : (
            <SkeletonBar num={1} length={36} />
          )}{" "}
        </div>
        <div className="flex">
          About me:{" "}
          {userData ? (
            userData.users_by_pk.about_me
          ) : (
            <SkeletonBar num={1} length={36} />
          )}
        </div>
        <h1 className="mt-4 text-xl font-bold text-gray-900">
          Areas of Experience List
        </h1>
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
          {userData ? (
            userData.users_by_pk.user_areas_of_experience.map((experience) => {
              return (
                <BtnNoIcon
                  key={experience.area_of_experience.id}
                  navigate={navigate}
                  navigateTo={`/areasofexperience/${experience.area_of_experience.description}`}
                  description={experience.area_of_experience.description}
                />
              );
            })
          ) : (
            <div className="flex flex-col space-y-3 col-span-6">
              <SkeletonBar num={2}/>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
