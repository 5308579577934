import { formatDistanceToNowStrict } from "date-fns";

export default function calculateTimeElapsed(startDate) {
  const formattedStartDate = new Date(startDate);

  const timeElapsed = formatDistanceToNowStrict(
    new Date(
      formattedStartDate.getFullYear(),
      formattedStartDate.getMonth(),
      formattedStartDate.getDate(),
      formattedStartDate.getHours(),
      formattedStartDate.getMinutes()
    )
  );

  return timeElapsed;
}
