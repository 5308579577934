export function SkeletonBar({ num, length }) {
  function getSkeletons(num, length) {
    let skeletonArr = [];
    for (let i = 0; i < num; i++) {
      skeletonArr.push(
        <div
          key={i}
          className={`inline-block ${
            length ? `w-${length}` : "w-full"
          } bg-gray-300 h-6 rounded-md`}
        ></div>
      );
    }
    return skeletonArr;
  }

  return <>{getSkeletons(num, length)}</>;
}
